import { createContext } from "react";

import { RegalAuthContextValue } from "App/main/RegalAuthContext";

import type { NullOr } from "@regal-voice/shared-types";
import type { Brand } from "Types/Brand";

export type LegacyUser = Pick<RegalAuthContextValue["user"], "email" | "firstName" | "lastName" | "name" | "roles">;

export type UserContextValue = {
    user: NullOr<LegacyUser>;
    brand: NullOr<Brand>;
};

export const UserContext = createContext<UserContextValue>({ user: null, brand: null });
