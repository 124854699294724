import { useSelector } from "react-redux";

import { selectAgentInformation } from "Services/state/agent/AgentInformationSlice";
import { selectBrand } from "Services/state/brand";
import { CommunicationSource } from "Types/CommunicationSource";

import type { Profile } from "Types/Profile";

type UseDefaultFromNumber = {
    defaultFromNumber: string;
};

export function useDefaultFromNumber({ contact }: { contact?: Partial<Profile> }): UseDefaultFromNumber {
    const brandInfo = useSelector(selectBrand);
    const agentInformation = useSelector(selectAgentInformation);
    const defaultBrandFromNumber = brandInfo?.defaultFromNumber;

    const { source: subBrandPhoneNumber } =
        ((contact?.rvProperties?.sub_brand &&
            brandInfo?.communicationSources?.find((communicationSource) =>
                Boolean(communicationSource.subBrand === contact?.rvProperties?.sub_brand)
            )) as CommunicationSource) || {};

    const defaultAgentPhoneNumber = agentInformation.attributes?.agentRegalVoicePhone;

    return {
        defaultFromNumber: defaultAgentPhoneNumber || subBrandPhoneNumber || defaultBrandFromNumber,
    };
}
