import { gql } from "@apollo/client";

import { EventSchema } from "Types/ContactAttribute";

export type EventNamesResponse = {
    getEventNames: {
        items: string[];
    };
};
export type EventSchemaListReturnType = {
    getEventSchema: EventSchema[];
};

export type EditableEventSchemaReturnType = {
    getEditableEventSchema: EventSchema[];
};

export const getEventNames = gql`
    query ($eventType: String!) {
        getEventNames(eventType: $eventType) {
            items
        }
    }
`;

export const getEventFields = gql`
    query ($eventType: String!, $eventName: String!) {
        getEventSchema(eventType: $eventType, eventName: $eventName) {
            fieldName
            fieldType
            fieldId
        }
    }
`;

export const getEditableEventFields = gql`
    query ($eventType: String!, $eventName: String!) {
        getEditableEventSchema(eventType: $eventType, eventName: $eventName) {
            fieldName
            fieldType
            fieldId
        }
    }
`;
