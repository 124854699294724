import { useCallback, useState } from "react";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { useFlags } from "Services/FeatureFlagService";

import NewContactListModal, {
    EMPTY_SEGMENT_VALUE,
} from "../../AgentPanes/AgentActionPaneSelection/ContactsPanel/Components/Components/NewContactListModal/NewContactListModal";
import { SavedViewGroup } from "./Components/SavedViewGroup/SavedViewGroup";
import { useSavedViewsContactListsData } from "./Hooks/useSavedViewsContactListsData";

import AdMenuStyles from "../AgentDesktopSideNav.module.scss";
import styles from "./ContactListSavedViews.module.scss";
type PropType = {
    pathname: string;
};
const MAX_LIST_COUNT = 10;
export function ContactListSavedViews({ pathname }: PropType): JSX.Element {
    const [newContactListModalVisible, setNewContactListModalVisible] = useState(false);
    const { contactListSavedViews, contactListCreateSavedViews } = useFlags();
    const toggleNewContactListModal = useCallback(() => {
        setNewContactListModalVisible((prev) => !prev);
    }, []);

    const { privateViews, sharedViews, refetch } = useSavedViewsContactListsData();
    const isCreateDisabled = privateViews.length >= MAX_LIST_COUNT;
    const nameList = privateViews.map((view) => view.name) || [];

    return (
        <>
            {contactListSavedViews && (
                <>
                    {!!privateViews.length && (
                        <SavedViewGroup title={"PRIVATE CONTACT LISTS"} items={privateViews} pathname={pathname} />
                    )}

                    {!!sharedViews.length && (
                        <SavedViewGroup title={"SHARED CONTACT LISTS"} items={sharedViews} pathname={pathname} />
                    )}
                </>
            )}
            {contactListCreateSavedViews && (
                <>
                    <div className={AdMenuStyles.menuWrap}>
                        <RVButton
                            className={styles.triggerButton}
                            icon="add-circle-outline"
                            type="link"
                            onClick={toggleNewContactListModal}
                            disabled={isCreateDisabled}
                        >
                            {"New Contact List"}
                        </RVButton>
                    </div>
                    {/* this modal is lauched from the new contact list button*/}
                    <NewContactListModal
                        initialFormData={EMPTY_SEGMENT_VALUE}
                        disallowedNames={nameList}
                        open={newContactListModalVisible}
                        onHide={toggleNewContactListModal}
                        refetch={refetch}
                    />
                </>
            )}
        </>
    );
}
