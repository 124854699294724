import { useCallback, useState, useMemo } from "react";

import { Tooltip, type TooltipProps } from "antd";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { isIframedInSalesforce } from "Services/CrossDomainMessenger";

import { useCollapsiblePanel } from "./CollapsiblePanelsContext";

export type ToggleCollapsiblePanelButtonProps = {
    direction: "left" | "right";
    panelName: string;
    tooltipPlacement?: Extract<NonNullable<TooltipProps["placement"]>, `top${string}`>;
    hideOnIframe?: boolean;
};

export function ToggleCollapsiblePanelButton({
    direction,
    panelName,
    tooltipPlacement,
    hideOnIframe = true,
}: ToggleCollapsiblePanelButtonProps) {
    const isEmbededView = isIframedInSalesforce();
    const [tooltipEnabled, setTooltipEnabled] = useState(true);
    const { collapsed, toggle } = useCollapsiblePanel(panelName);
    const info = useMemo(() => {
        const text = collapsed ? "Expand" : "Collapse";
        let icon: string;
        let defaultTooltipPlacement: TooltipProps["placement"];
        if (direction === "left") {
            icon = "page-collapser-left";
            defaultTooltipPlacement = "topLeft";
        } else {
            icon = "page-collapser-right";
            defaultTooltipPlacement = "topRight";
        }

        const placement = tooltipPlacement || defaultTooltipPlacement;
        let alignment: TooltipProps["align"];
        if (defaultTooltipPlacement === "topLeft") {
            alignment = { offset: [-7, 0] };
        } else {
            alignment = { offset: [7, 0] };
        }

        return { icon, text, placement, alignment, testId: `toggle-${panelName}-panel-button` };
    }, [collapsed, tooltipPlacement, direction, panelName]);

    const toggleAndBlur = useCallback<NonNullable<React.ComponentProps<typeof RVButton>["onClick"]>>(
        (event) => {
            toggle();
            event.currentTarget.blur();
            /**
             * This is necessary in order to avoid weird antd glitches when the panel is being animated.
             */
            setTooltipEnabled(false);
            setTimeout(() => setTooltipEnabled(true), 1000);
        },
        [toggle, setTooltipEnabled]
    );

    if (hideOnIframe && isEmbededView) {
        return null;
    }

    const buttonNode = (
        <RVButton data-testid={info.testId} icon={info.icon} size="middle" type="text" onClick={toggleAndBlur} />
    );

    if (tooltipEnabled) {
        return (
            <Tooltip title={info.text} placement={info.placement} align={info.alignment}>
                {buttonNode}
            </Tooltip>
        );
    }

    return buttonNode;
}
