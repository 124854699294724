/**
 * Brought in from usehooks https://github.com/uidotdev/usehooks/
 * https://github.com/uidotdev/usehooks/blob/2d55722e5b6f828eb498ccb0ff0a5ea6bc33d0e7/index.js#L121
 * We prefered this one over the usehooks-ts version because it handles errors and returns it as state
 * vs just a log
 *
 * Added a few types and tests to validate, but otherwise this is the same
 */
import { useState, useCallback } from "react";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<void>; // Return success

type CopiedState = {
    error: Error | null;
    text: CopiedValue;
};

function useCopyToClipboard(): [CopiedState, CopyFn] {
    const [clipState, setClipState] = useState<CopiedState>({
        error: null,
        text: null,
    });

    const copyToClipboard = useCallback(async (value: string) => {
        if (!navigator?.clipboard) {
            return setClipState({
                error: new Error("Clipboard not supported"),
                text: null,
            });
        }

        const handleSuccess = () => {
            setClipState({
                error: null,
                text: value,
            });
        };

        const handleFailure = (e: Error) => {
            setClipState({
                error: e,
                text: null,
            });
        };

        navigator.clipboard.writeText(value).then(handleSuccess, handleFailure);
    }, []);

    return [clipState, copyToClipboard];
}

export default useCopyToClipboard;
