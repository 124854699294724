export const postMessageToParent = (message: any, targetOrigin: string | string[]): void => {
    if (Array.isArray(targetOrigin)) {
        targetOrigin.forEach((origin) => {
            window.parent.postMessage(message, origin);
        });
    } else {
        window.parent.postMessage(message, targetOrigin);
    }
};

export const isIframed = (): boolean => {
    return typeof window == "undefined" ? false : window.location !== window.parent.location;
};

export function isIframedInSalesforce() {
    if (!isIframed()) {
        return false;
    }
    return getSalesforceOrigin()?.length >= 1;
}

export function isIframedInRetool() {
    if (!isIframed()) {
        return false;
    }
    return getRetoolOrigin()?.length >= 1;
}

export function getSalesforceOrigin() {
    return getAncestorOrigins().filter((origin) => origin.includes("force.com"));
}

export function getRetoolOrigin() {
    return getAncestorOrigins().filter((origin) => origin.includes("retool-edge.com"));
}

export function getAncestorOrigins() {
    // check ancestorOrigin domains to get the parent container url
    return Array.from(window.location.ancestorOrigins || []);
}
