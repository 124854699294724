import { gql } from "@apollo/client";

import type { Profile } from "Types/Profile";

export type GetProfileReturn = {
    getProfile: Profile;
};

export const getCustomProperties = gql`
    query {
        getCustomProperties {
            items
        }
    }
`;

export const getProfile = gql`
    query ($profileId: String, $contactPhone: String, $contactEmail: String) {
        getProfile(profileId: $profileId, contactPhone: $contactPhone, contactEmail: $contactEmail) {
            id
            name
            rvProperties
            optIn
            customProperties
            subscribedSms
            subscribedVoice
            subscribedEmail
            contactPhone
            email
            firstName
            lastName
            externalId
            lastContactedAt
            phones {
                label
                phoneNumber
                capabilities
                isPrimary
                voiceOptIn {
                    subscribed
                }
                smsOptIn {
                    subscribed
                }
            }
            emails {
                label
                email
                isPrimary
                emailOptIn {
                    subscribed
                }
            }
        }
    }
`;
