import { CopyOutlined, SelectOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message } from "antd";

import useCopyToClipboard from "Utils/useCopyToClipboard/useCopyToClipboard";

import styles from "./DropDownLink.module.scss";

export default function DropDownLink({
    link,
    position = "bottomLeft",
    short = true,
}: {
    link: string;
    short?: boolean;
    position?: "bottomRight" | "topLeft" | "topCenter" | "topRight" | "bottomLeft" | "bottomCenter" | undefined;
}): JSX.Element {
    const [, setCopied] = useCopyToClipboard();
    function getLinkDisplayValue(): string {
        const uuid = getUUIDFromString(link);
        if (uuid) {
            return uuid;
        } else {
            try {
                return short && link.length > 20 ? new URL(link).host : link;
            } catch {
                return link;
            }
        }
    }

    function getUUIDFromString(value: string): string {
        const split = value.match(/((\w{4,12}-?)){5}/);
        return split?.length ? split[0] : "";
    }

    function copyToClipboard() {
        setCopied(link);
        message.success("Link was copied!");
    }

    function validateHttp(str: string): string {
        if (!(str?.indexOf("http://") == 0 || str?.indexOf("https://") == 0)) {
            str = str?.replace(/^/, "https://");
        }
        return str;
    }

    return (
        <Dropdown
            placement={position}
            className={styles.urlLike}
            overlayStyle={{ minWidth: "unset" }}
            overlay={
                <Menu>
                    <Menu.Item key="copy" icon={<CopyOutlined />} onClick={copyToClipboard}>
                        Copy to clipboard
                    </Menu.Item>
                    <Menu.Item key="open" icon={<SelectOutlined />}>
                        <a href={validateHttp(link)} target="_blank" rel="noreferrer">
                            Open in new tab
                        </a>
                    </Menu.Item>
                </Menu>
            }
        >
            <a href={validateHttp(link)} target="_blank" rel="noreferrer">
                {getLinkDisplayValue()}
            </a>
        </Dropdown>
    );
}
