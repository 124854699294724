import { useRef, useState } from "react";

import { NullOr, UnPromisify } from "@regal-voice/shared-types";
import * as _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import { brandSlugFromAuth } from "Services/AuthService";
import { deepCaseConversion } from "Services/HelpersService";
import { getLogger } from "Services/LoggingService";
import { fetch } from "Services/util/NetworkUtilService";
const logger = getLogger("AbstractApiService");

export type ApiHeaders = {
    Authorization: string;
    "Content-Type"?: string;
    "X-Brand": string;
    "X-Regal-Voice-Authorization": string;
    // DEPRECATE THIS
    "Api-Key": "Bearer Token";
    "X-Twilio-Authorization": "Bearer Token";
};

type ServerConfigParams = {
    host: string;
    performCaseConversion?: boolean;
    performResponseCaseConversion?: boolean;
};

type RequestParams = {
    endpoint: string;
    errors: { notFound: string; other: string };
    body?: Record<string, any>;
    headers?: ApiHeaders;
    method?: "PATCH" | "POST" | "PUT" | "DELETE";
    skipDefaultErrorHandling?: boolean;
    signal?: AbortSignal;
    retryAttempts?: number;
    performCaseConversion?: boolean;
    performResponseCaseConversion?: boolean;
    credentials?: RequestCredentials;
};

/**
 * @todo (auth) Move those unnecessary headers to the API proxy.
 */
function generateApiHeaders({ brandSlug }: { brandSlug?: string }) {
    return {
        ...(brandSlug ? { "X-Brand": brandSlug } : {}),
        // Api-Key and X-Twilio-Authorization are not currently used, but ingest & voice still need there to be values for them
        "Api-Key": "Bearer Token",
        "X-Twilio-Authorization": "Bearer Token",
    };
}

export function apiHeaders(): ApiHeaders {
    const brandSlug = brandSlugFromAuth();
    return generateApiHeaders({ brandSlug }) as ApiHeaders; // cast it here, since individual pieces of this missing should be handled/rejected by the backend
}

export function configureServer({
    host,
    performCaseConversion: serverRequestCaseConversion = false,
    performResponseCaseConversion: serverResponseCaseConversion = true,
}: ServerConfigParams): <T>(requestParams: RequestParams) => Promise<T> {
    return async function makeRequest<T>({
        endpoint,
        errors,
        body,
        headers,
        method,
        skipDefaultErrorHandling,
        signal,
        retryAttempts,
        performCaseConversion = serverRequestCaseConversion,
        performResponseCaseConversion = serverResponseCaseConversion,
        credentials,
    }: RequestParams): Promise<T> {
        let params: {
            headers: Record<string, string>;
            method?: string;
            body?: string | FormData;
            signal?: AbortSignal;
            credentials: RequestCredentials;
        } = {
            headers: {
                ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
                ...(headers ? headers : apiHeaders()),
            },
            ...(signal ? { signal } : {}),
            credentials: "same-origin",
        };
        // How FormData: https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
        if (!_.isEmpty(body) || !_.isEmpty(method)) {
            params = {
                ...params,
                method: method ?? "POST",
                body:
                    body instanceof FormData
                        ? body
                        : JSON.stringify(performCaseConversion ? deepCaseConversion(body, _.snakeCase) : body),
            };
        }

        return fetch(`${host}${endpoint}`, params)
            .then((response: Response) => {
                if (response.ok) {
                    switch (headers?.["Content-Type"]) {
                        case "text/csv":
                            return response;
                        default:
                            return response.json();
                    }
                }

                if (response.status == 400 || skipDefaultErrorHandling) {
                    return response.json().then((e) => {
                        throw e;
                    });
                } else if (response.status == 404) {
                    throw errors.notFound;
                }

                throw errors.other;
            })
            .then((res) => {
                if (performResponseCaseConversion) {
                    // do conversion back to camelCase
                    return deepCaseConversion(res, _.camelCase);
                }
                return res;
            })
            .catch((error) => {
                const isRequestError = error.message?.indexOf("Failed to fetch") >= 0;

                logger.error(`Fetch Error: ${error?.message}`, { error, isRequestError });
                if (isRequestError && retryAttempts) {
                    return makeRequest({
                        endpoint,
                        errors,
                        body,
                        headers,
                        method,
                        skipDefaultErrorHandling,
                        signal,
                        retryAttempts: retryAttempts - 1,
                        credentials,
                    });
                }
                throw error;
            });
    };
}

export function useApi<T extends (...args: any[]) => Promise<any>>(
    fn: T,
    ...args: Parameters<T>
): [NullOr<UnPromisify<ReturnType<T>>>, NullOr<string>, boolean, (...argOverrides: Parameters<T> | []) => void] {
    const [response, setResponse] = useState<NullOr<UnPromisify<ReturnType<T>>>>(null);
    const [error, setError] = useState<NullOr<string>>(null);
    const [loading, setLoading] = useState(false);

    const fetch = (...argOverrides: Parameters<T> | []) => {
        setResponse(null);
        setError(null);
        setLoading(true);

        const argsForFetch = (argOverrides.length ? argOverrides : args || []) as Parameters<T>;

        fn(...argsForFetch)
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    // WARNING: USE WITH CAUTION (copy this as well) - if considering `useDeepCompareEffect`, confirm that other approaches cannot solve the issue
    // eg, key lists or array length.  deep compare effect is very computationally heavy and relatively error prone, should only
    // be used as a last resort
    useDeepCompareEffect(() => {
        fetch();
        // could eventually return an AbortController here to kill all fetches when naving away
    }, [args]);

    return [response, error, loading, fetch];
}

export function useApiWithAbort<T extends (...args: any[]) => Promise<any>>(
    fn: T,
    ...args: Parameters<T>
): [NullOr<UnPromisify<ReturnType<T>>>, NullOr<string>, boolean, (...argOverrides: Parameters<T> | []) => void] {
    const [response, setResponse] = useState<NullOr<UnPromisify<ReturnType<T>>>>(null);
    const [error, setError] = useState<NullOr<string>>(null);
    const [loading, setLoading] = useState(false);

    const abortController = useRef<AbortController>();

    const fetch = () => {
        setResponse(null);
        setError(null);
        setLoading(true);

        abortController.current = new AbortController();
        const { signal } = abortController.current;
        fn(...[...(args || []), ...[signal]])
            .then(setResponse)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    // WARNING: USE WITH CAUTION (copy this as well) - if considering `useDeepCompareEffect`, confirm that other approaches cannot solve the issue
    // eg, key lists or array length.  deep compare effect is very computationally heavy and relatively error prone, should only
    // be used as a last resort
    useDeepCompareEffect(() => {
        if (abortController.current) {
            abortController.current?.abort();
        }

        fetch();
        // could eventually return an AbortController here to kill all fetches when naving away
    }, [args]);

    return [response, error, loading, fetch];
}
