import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UpdateCustomObjectEntryResponse } from "Pages/agentDesktop/agentInterface/InfoPanel/UserInfoPanel/CustomObjectDisplay/mutations";
import { renderErrorMessage } from "Services/LoggingService";
import { CustomObjectEntry } from "Types/CustomObjectEntry";

type CustomObject = {
    id: string;
    name: string;
    entries: CustomObjectEntry[];
};

export type CustomObjectEntriesState = {
    customObjects: CustomObject[];
};

const initialState: CustomObjectEntriesState = {
    customObjects: [],
};

const customObjectEntriesStateSlice = createSlice({
    name: "CustomObjectEntries",
    initialState,
    reducers: {
        setCustomObjects(state, action: PayloadAction<CustomObject[]>) {
            state.customObjects = action.payload;
        },
        setCustomObjectEntry(state, action: PayloadAction<UpdateCustomObjectEntryResponse>) {
            const customObjectIndex = state.customObjects.findIndex(
                (customObject) => customObject.id === action.payload.definitionId
            );
            if (customObjectIndex < 0) {
                renderErrorMessage({ content: `Custom object type ${action.payload.definitionId} not found` });
                return;
            }

            const customObject = state.customObjects[customObjectIndex];
            const customObjectEntryIndex = customObject.entries.findIndex(
                (customObjectEntry) => customObjectEntry.id === action.payload.entry.id
            );
            if (customObjectIndex < 0) {
                renderErrorMessage({ content: `Custom object ${action.payload.entry.id} not found` });
                return;
            }

            state.customObjects[customObjectIndex].entries[customObjectEntryIndex] = action.payload.entry;
        },
        clearCustomObjects(state) {
            state.customObjects = [];
        },
    },
});

export const { setCustomObjects, clearCustomObjects, setCustomObjectEntry } = customObjectEntriesStateSlice.actions;
export default customObjectEntriesStateSlice.reducer;
