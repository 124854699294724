import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { NullOr, EmailAddress, EmailTaskAttributes, EmailSentOptimisticEvent } from "@regal-voice/shared-types";

import { selectPageAwareContactObject } from "Services/state/contacts/Selectors/Selectors";
import { RootState } from "Services/state/Storage";

import { Attachment } from "../AgentDesktopUISlice";

// Types
export interface NewEmailMeta {
    cc: string[];
    bcc: string[];
    to: string[];
    subject: string;
    agentEmail: string;
    inReplyToRvMessageId?: string;
}

export const EmailSendStatus = {
    DRAFT: "draft",
    PENDING: "pending",
    ERROR: "error",
} as const;

export interface EmailUIState {
    newEmailMeta: NewEmailMeta;
    sendAttemptStatus: (typeof EmailSendStatus)[keyof typeof EmailSendStatus];
    optimisticEmails: EmailSentOptimisticEvent[];
    emailEventToRespondTo: NullOr<EmailTaskAttributes["originatingEmailEvent"]>;
}

// Utils
function parseAddressFromEmailAddressObj(addressObj: Pick<EmailAddress, "address">): string {
    return addressObj.address;
}
export function generateNewEmailMeta({
    emailEventToRespondTo,
    agentEmail,
    contactEmail,
}: {
    emailEventToRespondTo: NullOr<EmailTaskAttributes["originatingEmailEvent"]>;
    agentEmail: string;
    contactEmail: string;
}): NewEmailMeta {
    if (!emailEventToRespondTo) {
        return {
            agentEmail,
            cc: [],
            bcc: [],
            subject: "",
            // if the contact email is empty, we don't want to see an empty tag
            to: contactEmail?.length ? [contactEmail] : [],
        };
    }
    const { subject } = emailEventToRespondTo;
    const replySubject = subject.indexOf("Re: ") === 0 ? subject : "Re: " + subject;
    const replyCc = emailEventToRespondTo.ccEmails?.map(parseAddressFromEmailAddressObj) || [];
    const replyToAddressObjects = [
        emailEventToRespondTo.senderEmail,
        {
            address: contactEmail,
        },
        ...emailEventToRespondTo.toEmails.filter((toEmail) => toEmail.address !== agentEmail),
    ];
    const replyToEmailsAddressesWithDupes = replyToAddressObjects.map(parseAddressFromEmailAddressObj);
    const replyTo = [...new Set(replyToEmailsAddressesWithDupes)];

    return {
        agentEmail,
        cc: replyCc,
        bcc: [],
        subject: replySubject,
        to: replyTo,
        inReplyToRvMessageId: emailEventToRespondTo.messageId,
    };
}

export type OptimisticProps = {
    attachments: Array<Attachment>;
    state: NewEmailMeta;
    agentEmail: EmailAddress;
    text: string;
    subject: string;
    contactPhone?: string;
    profileId: string;
    rvEmailMessageId: string;
    rvThreadId: string;
};
export const createOptimisticEmail = ({
    attachments = [],
    rvEmailMessageId,
    rvThreadId,
    state,
    agentEmail,
    text,
    subject,
    contactPhone,
    profileId,
}: OptimisticProps): EmailSentOptimisticEvent => {
    // small helpers to map the email string to the EmailAddress type
    const EmailAddressMapper = (email: string): EmailAddress => {
        return {
            address: email,
            name: "",
        };
    };

    // just a number I picked.
    const SNIPET_LEN = 140;

    // the activity event expects time in second, beacsue that is how
    // ingest sends it down.
    const timeInSeconds = Math.floor(new Date().getTime() / 1000);

    const convertedEmailText = convertHtmlToPlainText(text);
    const emailAttachments = attachments.map(({ name: fileName, type: fileType, localUrl }) => {
        return {
            fileName,
            fileType,
            localUrl,
        };
    });

    return {
        attachments: emailAttachments,
        eventType: "email",
        name: "Email Sent",
        direction: "outbound",
        senderEmail: agentEmail,
        // only the first 140 characters and no new lines
        bodySnippet: convertedEmailText.substring(0, SNIPET_LEN).replace(/\n/g, " "),
        threadId: rvThreadId,
        messageId: rvEmailMessageId,
        subject: subject,
        toEmails: state.to.map(EmailAddressMapper),
        ccEmails: state.cc.map(EmailAddressMapper),
        bccEmails: state.bcc.map(EmailAddressMapper),
        contactPhone,
        profileId,
        source: "regal",
        deliveredToEmails: [
            ...state.to.map(EmailAddressMapper),
            ...state.cc.map(EmailAddressMapper),
            ...state.bcc.map(EmailAddressMapper),
        ],
        createdAt: timeInSeconds,
        rvInfo: rvEmailMessageId,
        isOptimisticRender: true,
        optimisticContent: text,
    };
};

export function convertHtmlToPlainText(html: string) {
    const htmlWithTextLineBreaks = html.replaceAll("<br>", "\n");
    // Create a new div element
    const tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = htmlWithTextLineBreaks;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function getInitialReducerState(
    emailEventToRespondTo: NullOr<EmailTaskAttributes["originatingEmailEvent"]>,
    senderEmail: string,
    contactEmail: string
): EmailUIState {
    return {
        sendAttemptStatus: EmailSendStatus.DRAFT,
        newEmailMeta: generateNewEmailMeta({ emailEventToRespondTo, agentEmail: senderEmail, contactEmail }),
        optimisticEmails: [],
        emailEventToRespondTo,
    };
}

// The slice
const emailInputSlice = createSlice({
    name: "emailInput",
    initialState: getInitialReducerState(null, "", ""),
    reducers: {
        newEmailEvent: (state, action: PayloadAction<Parameters<typeof generateNewEmailMeta>[0]>) => {
            state.newEmailMeta = generateNewEmailMeta(action.payload);
            state.emailEventToRespondTo = action.payload.emailEventToRespondTo;
        },
        emailSendAttempted: (state) => {
            state.sendAttemptStatus = EmailSendStatus.PENDING;
        },
        emailSendFinished: (state) => {
            state.sendAttemptStatus = EmailSendStatus.DRAFT;
        },
        emailErrorReset: (state) => {
            // we just return to draft status. the Error message is handled elsewhere
            state.sendAttemptStatus = EmailSendStatus.DRAFT;
        },
        emailRecipientsToChanged: (state, action: PayloadAction<string[]>) => {
            state.newEmailMeta.to = action.payload.filter(valueIsNonEmptyString);
        },
        emailRecipientsCcChanged: (state, action: PayloadAction<string[]>) => {
            state.newEmailMeta.cc = action.payload.filter(valueIsNonEmptyString);
        },
        emailRecipientsBccChanged: (state, action: PayloadAction<string[]>) => {
            state.newEmailMeta.bcc = action.payload.filter(valueIsNonEmptyString);
        },
        emailSubjectUpdated: (state, action: PayloadAction<string>) => {
            state.newEmailMeta.subject = action.payload;
        },
        addOptimisticEmail(state, action: PayloadAction<EmailSentOptimisticEvent>) {
            state.optimisticEmails.push(action.payload);
        },
    },
});

export const {
    newEmailEvent: _newEmailEventAction,
    emailSendAttempted,
    emailSendFinished,
    emailErrorReset,
    emailRecipientsToChanged,
    emailRecipientsCcChanged,
    emailRecipientsBccChanged,
    emailSubjectUpdated,
    addOptimisticEmail,
} = emailInputSlice.actions;

export default emailInputSlice.reducer;

export const EmailUISliceSelector = (state: RootState): EmailUIState => state.emailUI;

export const selectNewEmailMeta = createSelector(
    EmailUISliceSelector,
    (emailInputState) => emailInputState.newEmailMeta
);

export const selectSendAttemptStatus = createSelector(
    EmailUISliceSelector,
    (emailInputState) => emailInputState.sendAttemptStatus
);

export function valueIsNonEmptyString(value: any) {
    return Boolean(typeof value === "string" && value.trim().length);
}

export const selectTo = createSelector(selectNewEmailMeta, (newEmailMeta) =>
    newEmailMeta.to.filter(valueIsNonEmptyString)
);

export const selectCc = createSelector(selectNewEmailMeta, (newEmailMeta) =>
    newEmailMeta.cc.filter(valueIsNonEmptyString)
);

export const selectBcc = createSelector(selectNewEmailMeta, (newEmailMeta) =>
    newEmailMeta.bcc.filter(valueIsNonEmptyString)
);

export const selectSubject = createSelector(selectNewEmailMeta, (newEmailMeta) => newEmailMeta.subject);

export const selectAgentEmail = createSelector(selectNewEmailMeta, (newEmailMeta) => newEmailMeta.agentEmail);

export const selectInReplyToRvMessageId = createSelector(
    selectNewEmailMeta,
    (newEmailMeta) => newEmailMeta.inReplyToRvMessageId
);

export const selectOptimisticEmails = createSelector(EmailUISliceSelector, (emailUI) => emailUI.optimisticEmails);

export const selectOptimisticEmailsForActiveContact = createSelector(
    selectOptimisticEmails,
    selectPageAwareContactObject,
    (optimisticEmails, activeContact) => {
        if (!activeContact) {
            return [];
        }
        return optimisticEmails.filter((email) => email.profileId === activeContact.id);
    }
);

export const selectEmailEventToRespondTo = createSelector(
    EmailUISliceSelector,
    (emailUI) => emailUI.emailEventToRespondTo
);
