import { useState, useEffect, Ref, useCallback } from "react";

import { useLazyQuery } from "@apollo/client";
import { Dropdown, InputRef } from "antd";

import { DebouncedInput } from "Components/shared/DebouncedInput/DebouncedInput";
import { VALID_PHONE_CHARACTERS_REGEX } from "Services/CommunicationService";
import { useFlags } from "Services/FeatureFlagService";
import { OperatorType, QueryParams } from "Types/QueryParams";

import { DialPadActions } from "../SearchableDialPad.types";
import ContactSearchActions from "./ContactSearchActions/ContactSearchActions";
import ContactSearchDropdown from "./ContactSearchDropdown/ContactSearchDropdown";
import { getPhonesEmails } from "./queries";

import type { GetProfilesQueryReturn } from "../SearchableDialPad.types";
import type { Profile } from "Types/Profile";

import styles from "./ContactSearch.module.scss";

// TODO move somewhere else once we confirm this is what we want to actually check
// it's important to note that we might be in a situation where
// the user didn't type the full value yet
// and we are trying to determine the search type as soon as possible
const INVALID_EMAIL_REGEX = /[\s]/;
const INVALID_NAME_REGEX = /[@]/;

const defaultVariables: Partial<QueryParams> = {
    orderBy: [
        {
            key: "updatedAt",
            value: "DESC",
        },
    ],
    page: 0,
    pageSize: 25,
};

// small wrapper to make the input more reusable
export function ContactInput({
    suffix,
    loadMatchingContacts,
    inputRef,
    defaultPhone,
}: {
    suffix: JSX.Element | null;
    loadMatchingContacts: (value: string | null) => void;
    inputRef?: Ref<InputRef>;
    defaultPhone?: string;
}): JSX.Element {
    return (
        <DebouncedInput
            action={loadMatchingContacts}
            placeholder="Enter a Name, Phone Number, Email or External ID"
            suffix={suffix}
            debounceMs={500}
            autoFocus
            inputRef={inputRef}
            className={styles.searchInput}
            defaultValue={defaultPhone as string}
            key={defaultPhone as string}
            data-testid="contact-debounced-input"
            autocomplete="off"
        />
    );
}

// This is a function that returns the element that the dropdown will be attached to
const wrapperId = "contactSearchDropdownWrapper";
const getDropdownContainer = () => document.getElementById(wrapperId) as HTMLElement;

export function ContactSearch({
    onContactSelect,
    inputRef,
    setDialPadNumber,
    defaultPhone,
}: {
    onContactSelect: (contact: Partial<Profile>, action: DialPadActions) => void;
    inputRef: Ref<InputRef>;
    setDialPadNumber: (searchedData: string | undefined) => void;
    defaultPhone?: string;
}): JSX.Element {
    const { noAutocompleteDialpad } = useFlags();
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string | null>(null);
    const [searchedBy, setSearchedBy] = useState<"contactPhone" | "contactName" | "email" | undefined>(undefined);
    const [loadContacts, { loading, data }] = useLazyQuery<GetProfilesQueryReturn>(getPhonesEmails, {
        fetchPolicy: "no-cache",
        onCompleted: handleDropdownVisibility,
    });

    const responseData = data?.getPhonesEmails.items;

    // the default action is to do a search and display the results in the dropdown
    const loadMatchingContacts = useCallback(
        (value: string | null) => {
            if (!value) {
                setDialPadNumber(undefined);
                setUserInput(null);
                setDropdownVisible(false);
                return;
            }

            setUserInput(value);
            const doSearchBy = VALID_PHONE_CHARACTERS_REGEX.test(value)
                ? "contactPhone"
                : INVALID_NAME_REGEX.test(value)
                ? "email"
                : "contactName";

            setDialPadNumber(doSearchBy === "contactPhone" ? value : undefined);
            setSearchedBy(doSearchBy);
            setDropdownVisible(true);

            const filterBy = [
                {
                    key: doSearchBy === "contactName" ? "name" : doSearchBy,
                    value,
                    operator: OperatorType.STARTS_WITH,
                },
            ];

            if (doSearchBy === "contactName" && !INVALID_EMAIL_REGEX.test(value)) {
                // if we think the user is searching for a name
                // but at the same time it's not an invalid value for an email
                // we add the email filter to the seach
                filterBy.push({
                    key: "email",
                    value,
                    operator: OperatorType.STARTS_WITH,
                });
            }

            loadContacts({
                variables: {
                    queryParams: {
                        ...defaultVariables,
                        filterBy,
                    },
                },
            });
        },
        [loadContacts, setDialPadNumber]
    );

    useEffect(() => {
        if (defaultPhone) {
            setUserInput(defaultPhone);
            loadMatchingContacts(defaultPhone);
        }
    }, [defaultPhone, loadMatchingContacts]);

    // A function just set the data we need to
    // enable the suffix actions for direct dialing
    function mockSearch(value: string | null): void {
        if (!value) {
            setUserInput(null);
            setDialPadNumber(undefined);
            setDropdownVisible(false);
            return;
        }

        setUserInput(value);
        setDialPadNumber(value);
        setSearchedBy("contactPhone");
    }

    const onMenuItemSelect = (contact: Partial<Profile>, action: DialPadActions) => {
        onContactSelect(contact, action);
        setDropdownVisible(false);
    };
    function handleDropdownVisibility(queryData: GetProfilesQueryReturn): void {
        setDropdownVisible(!!queryData);
    }

    // swap the value to undefined if it is null
    const contactPhone = userInput ?? undefined;

    // we always want to show the suffix when noAutocompleteDialpad is true
    // It will be disabled if the input is not valid just like when it is shown normally
    const showSuffix =
        noAutocompleteDialpad || (userInput && searchedBy === "contactPhone" && !responseData?.length && !loading);

    const suffix = showSuffix ? (
        <ContactSearchActions value={{ contactPhone }} onClick={onMenuItemSelect} disabled={!userInput} />
    ) : null;

    return (
        <div onClick={(e) => e.stopPropagation()} id={wrapperId} data-testid={wrapperId}>
            {noAutocompleteDialpad ? (
                // Simple input for orgs that dont want to allow user to dial known numbers
                <ContactInput suffix={suffix} inputRef={inputRef} loadMatchingContacts={mockSearch} />
            ) : (
                // Drop down input for orgs that want to allow user to search for numbers
                <Dropdown
                    overlay={
                        <ContactSearchDropdown
                            data={responseData}
                            loading={loading}
                            searchedBy={searchedBy}
                            searchedData={userInput}
                            onMenuItemSelect={onMenuItemSelect}
                        />
                    }
                    open={dropdownVisible}
                    getPopupContainer={getDropdownContainer}
                >
                    <ContactInput
                        suffix={suffix}
                        inputRef={inputRef}
                        loadMatchingContacts={loadMatchingContacts}
                        defaultPhone={defaultPhone as string}
                    />
                </Dropdown>
            )}
        </div>
    );
}
